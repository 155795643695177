// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/markwarfield/git/p3physio/p3physio-site/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-booking-js": () => import("/Users/markwarfield/git/p3physio/p3physio-site/src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-contact-js": () => import("/Users/markwarfield/git/p3physio/p3physio-site/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/Users/markwarfield/git/p3physio/p3physio-site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-physio-js": () => import("/Users/markwarfield/git/p3physio/p3physio-site/src/pages/physio.js" /* webpackChunkName: "component---src-pages-physio-js" */),
  "component---src-pages-pilates-js": () => import("/Users/markwarfield/git/p3physio/p3physio-site/src/pages/pilates.js" /* webpackChunkName: "component---src-pages-pilates-js" */),
  "component---src-pages-run-js": () => import("/Users/markwarfield/git/p3physio/p3physio-site/src/pages/run.js" /* webpackChunkName: "component---src-pages-run-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/markwarfield/git/p3physio/p3physio-site/.cache/data.json")

